
<template>
    <div class="through-page chart-page--container flex-item">
        <div class="common-table__title">按疾病找药</div>

        <div class="through-page-panel">
            <div class="through-page-assist">
                <el-popover placement="right-start" width="280" trigger="hover">
                    <el-button slot="reference" type="primary" size="small">指引</el-button>
                    <p class="through-page-tips" v-if="activeName === 'first'">
                        1、<span class="red">红色框</span>中的数字表示该药品的成分数量；<br>
                        2、点击<span class="red">红色框</span>可以查看该药品成分及数量情况；<br>
                        3、<span class="blue">蓝色框</span>中的数字表示该药品的成分数量；<br>
                        4、点击<span class="blue">蓝色框</span>可以查看该成分入药数量、近年销售金额及排名；<br>
                    </p>
                    <p class="through-page-tips" v-else>
                        1、<span class="red">红色框</span>中的数字表示该疾病适应症药品数量；<br>
                        2、点击<span class="red">红色框</span>可以查看该疾病适应症药品数量及近年销售排名<br>
                        3、<span class="blue">蓝色框</span>中的数字表示该药品的适应症/功能主治数量；<br>
                        4、点击<span class="blue">蓝色框</span>可以查看该药品的适应症/功能主治的数量及治疗该适应症/功能主治的同类药品数量<br>
                    </p>
                </el-popover>
                <div v-show="showSelect" class="through-page-assist__center">
                    <el-tabs v-model="activeName" style="margin-bottom: 10px;">
                        <el-tab-pane label="按药品查疾病" name="first"></el-tab-pane>
                        <el-tab-pane label="按疾病查药品" name="second"></el-tab-pane>
                    </el-tabs>
                    <div v-show="activeName === 'first' && showSelect">
                        <el-select class="flex-item" size="small" v-model="model" filterable remote reserve-keyword @change="loadData"
                                   placeholder="请输入药品关键词" :remote-method="remoteMethod" :loading="dataLoading" value-key="comCode">
                            <el-option v-for="item in option" :key="item.id" :label="item.comName"  :value="item"></el-option>
                        </el-select>
                    </div>

                    <div  v-show="activeName === 'second' && showSelect">
                        <el-select class="flex-item" size="small" v-model="model1" filterable remote reserve-keyword @change="loadData1"
                                   placeholder="请输入疾病关键词" :remote-method="remoteMethod1" :loading="dataLoading1" value-key="indicationCode">
                            <el-option v-for="item in option1" :key="item.indicationCode" :label="item.indication"  :value="item"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="through-page-assist__bottom">
                    <i class="clickable el-icon-caret-bottom" :class="{active: showSelect}" @click="showSelect = !showSelect"></i>
                </div>
            </div>
            <div class="through-page-content">
                <left-component ref="first" class="through-page-content__full" :message="message" 
                :class="{show: activeName === 'first'}" @goSecond="goSecond" @goIndication="goIndication" v-if="finished.first"></left-component>
                <right-component ref="second" class="through-page-content__full" :message="message1" 
                :class="{show: activeName === 'second'}" @goIndication="goIndication"></right-component>
            </div>
        </div>
    </div>
</template>

<script>
    import leftComponent from './indication-left'
    import rightComponent from './indication-right'
    export default {
        components: {leftComponent, rightComponent},
        data() {
            return {
                showSelect: true,
                visitId: new Date().getTime(),
                pageId: 'throughIndication',
                pageName: '按疾病找药',

                activeName: 'first',
                finished: {
                    first: false,
                    second: false,
                },


                model: {},
                dataLoading: false,
                message: '',
                option: [],

                model1: {},
                dataLoading1: false,
                option1: [],
                message1: '',
            }
        },
        mounted() {
            const code = this.$route.query.code
            const name = this.$route.query.name
            if (code) {
                this.model1 = {
                    indicationCode:code,
                    indication:name
                }
                this.option1 = [
                    {
                        indicationCode:code,
                        indication:name
                    }
                ]
                this.remoteMethod1(this.$route.query.name)
                this.goSecond({
                    indicationCode: code,
                    indication: name,
                })
            } else {
                this.remoteMethod1()
            }
            this.$help.socket.send(this)

            this.remoteMethod()
        },
        watch: {
            activeName: {
                handler(val) {
                    this.finished[val] = true
                },
                immediate: true
            },
        },
        methods: {
            goIndication(row){
                this.activeName = 'second'
                this.model1 = {
                    indication:row.indication,
                    indicationCode:row.indicationCode
                }
                this.option1 = [
                    {
                        indicationCode:row.indicationCode,
                        indication:row.indication
                    }
                ]
                this.loadData1()
                console.log('点击疾病111111',row);
            },
            goSecond(data) {
                this.$refs.second.init(data)
                this.activeName = 'second'
            },
            remoteMethod(keyword = '') {
                this.dataLoading = true
                this.$api.get('dr/baseComIndication/queryComByKeyword', {keyword}, {'Visit-Id': this.$help.getVisitId(this.visitId)})
                    .then(res => {
                        if (res.success) {
                            this.option = res.data

                            if (Object.keys(this.model).length === 0 && this.option.length > 0) {
                                this.model = this.option[0]
                                this.loadData()
                            }
                        } else {
                            this.message = res.message
                        }
                    })
                    .finally(() => {
                        this.dataLoading = false
                    })
            },
            loadData() {
                this.$refs.first.init(this.model)
            },



            remoteMethod1(keyword = '') {
                this.dataLoading1 = true
                this.$api.get('dr/baseComIndication/queryIndicationByKeyword', {keyword}, {'Visit-Id': this.$help.getVisitId(this.visitId)})
                    .then(res => {
                        if (res.success) {
                            this.option1 = res.data

                            if (Object.keys(this.model1).length === 0 && this.option1.length > 0 && !this.$route.query.code) {
                                this.model1 = this.option1[0]
                                this.loadData1()
                            }
                        } else {
                            this.message1 = res.message
                        }
                    })
                    .finally(() => {
                        this.dataLoading1 = false
                    })
            },
            loadData1() {
                this.$refs.second.init(this.model1)
            },
        }
    }
</script>