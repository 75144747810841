<template>
    <div v-loading="loading">
        <ts-error v-if="message"></ts-error>
        <template v-else>
            <div :id="key" v-if="loaded" class="through-page-canvas"></div>
            <through-table ref="through" :col="cols" @goIndication="goIndication" ></through-table>
        </template>
    </div>
</template>

<script>
import ThroughTable from "./ThroughTable.vue"
import throughMixin from './throughMixin'
    export default {
        components: {ThroughTable},
        mixins: [throughMixin],
        props: ['message'],
        data() {
            return {
                key: 'throughIndicationRight',

                dataLoading: false,
                model: {},
                option: [],

                loaded: true,

                config: {
                    type: 'indication',
                    query: 'dr/baseComIndication/comToIndicationPage',
                    queryField: 'comCode',   // 查询参数字段
                    keywordField: 'indication', // 搜索字段
                    showField: 'indication',  // 显示字段
                    saleAmount:'saleAmount',
                    countField: 'comNumber',  // 计数字段
                    color: '#FFCF0F'
                },
                drugConfig: {
                    type: 'drug',
                    nextType: 'indication',
                    query: 'dr/baseComIndication/indicationToComPage',
                    queryField: 'indicationCode',   // 查询参数字段
                    keywordField: 'comName', // 搜索字段
                    showField: 'comName',  // 显示字段
                    saleAmount:'saleAmount',
                    countField: 'indicationNumber',  // 计数字段
                    color: '#576EEB',
                    countShow: '疾病数量',
                },

                // 适应症查看适应症的配置
                indicationConfig: {
                    type: 'indication1',
                    query: 'dr/baseComIndication/indicationToChildrenPage',
                    queryField: 'parentIndicationCode',   // 查询参数字段
                    keywordField: 'indication', // 搜索字段
                    showField: 'indication',  // 显示字段
                    saleAmount:'saleAmount',
                    countField: 'comNumber',  // 计数字段
                    // hideCount: true,
                    color: '#FFCF0F',
                    countShow: '药品数量',
                },
                cols:[
                    {key:'baseDrug',value:'基药'},
                    {key:'medicalInsurance',value:'医保'},
                ]
                
            }
        },
        created() {
            // this.remoteMethod()
        },
        methods: {
            remoteMethod(keyword = '') {
                this.dataLoading = true
                this.$api.get('dr/baseComIndication/queryIndicationByKeyword', {keyword})
                    .then(res => {
                        this.option = res.data
                    })
                    .finally(() => {
                        this.dataLoading = false
                    })
            },

            // 获取该适应症的父和子适应症和药品
            getIndicationChildren(indication) {
                return new Promise(resolve => {
                    let categoryRes = {}
                    this.loading = true
                    this.$api.post('dr/baseComIndication/indicationToChildrenPage', {
                        page: {
                            pageNo: 1,
                            pageSize: this.pageSize,
                        },
                        params: {parentIndicationCode: indication.indicationCode}
                    })
                        .then(res => {
                            categoryRes = res.data
                        })
                        .finally(() => {
                            let drugRes = {}
                            this.$api.post('dr/baseComIndication/indicationToComPage', {
                                page: {
                                    pageNo: 1,
                                    pageSize: this.pageSize,
                                },
                                params: {indicationCode: indication.indicationCode}
                            })
                                .then(res => {
                                    drugRes = res.data
                                })
                                .finally(() => {
                                    this.loading = false
                                    let myArr = [{
                                        id: this.$help.getUuid(),
                                        loaded: true,
                                        end: true,
                                        style: {
                                            fill: '#E98B00',
                                        },
                                        lineWidth: 60,
                                        // nodeType: 'rect',
                                        label: `子适应症(${categoryRes.totalCount})`,
                                        children: categoryRes.records.map((item) => {
                                            return {
                                                id: this.$help.getUuid(),
                                                loaded: false,
                                                // end: index === categoryRes.totalCount - 1,
                                                // label: `${item.indication}(${item.comNumber}药品)`,
                                                label: item.indication,
                                                data: item,
                                                style: {
                                                    fill: '#FFCF0F',
                                                },
                                                // nodeType: 'rect',
                                                children: [],
                                                type: 'indication',
                                            }
                                        })
                                    },]
                                    if (categoryRes.totalCount > 0) {
                                        const obj = this.$help.deepClone(indication)
                                        obj.parentIndicationCode = obj.indicationCode
                                        myArr[0].children.push({
                                            id: this.$help.getUuid(),
                                            label: '查看全部',
                                            isFull: true,
                                            data: {},
                                            // end: true,
                                            style: {
                                                fill: '#DC2525',
                                            },
                                            parentTitle: obj.indication,
                                            parentModel: obj,
                                            config: this.indicationConfig,
                                            children: [],
                                        })
                                    }


                                    const drugObj = {
                                        id: this.$help.getUuid(),
                                        loaded: true,
                                        style: {
                                            fill: '#576EEB',
                                        },
                                        lineWidth: 60,
                                        label: `药品(${drugRes.totalCount})`,
                                        children: drugRes.records.map(item => {
                                            return {
                                                id: this.$help.getUuid(),
                                                loaded: false,
                                                label: `${item.comName}(${item.indicationNumber || 0})`,
                                                data: item,
                                                children: [],
                                                style: {
                                                    fill: '#576EEB',
                                                },
                                                type: 'drug',
                                                config: this.config,
                                                parentTitle: indication.indication,
                                                parentModel: indication,
                                            }
                                        })
                                    }
                                    if (drugRes.totalCount > 0) {
                                        drugObj.children.push({
                                            id: this.$help.getUuid(),
                                            label: '查看全部',
                                            isFull: true,
                                            data: {},
                                            // end: true,
                                            style: {
                                                fill: '#DC2525',
                                            },
                                            parentTitle: indication.indication,
                                            parentModel: indication,
                                            config: this.drugConfig,
                                            children: [],
                                        })
                                    }
                                    myArr.push(drugObj)
                                    resolve(myArr)
                                })
                        })
                })
            },

            init(model) {
                console.log(model)
                this.model = model
                this.loading = true
                this.loaded = false
                this.$nextTick(() => {
                    this.loaded = true
                })
                this.getIndicationChildren(this.model, true)
                    .then(res => {
                        const data = {
                            id: this.$help.getUuid(),
                            loaded: true,
                            label: this.model.indication,
                            data: this.model,
                            style: {
                                fill: '#FFCF0F',
                            },
                            lineWidth: 40,
                            children: res
                        }
                        this.antv = this.renderG6(data)

                        this.antv.on('node:click', evt => {
                            const item = evt.item
                            const model = item.getModel()
                            if (model.isFull) {
                                this.$refs.through.init(model)
                            } else if (!model.loaded) {
                                model.loaded = true
                                this.loading = true
                                if (model.type === 'drug') {
                                    this.$api.post(this.config.query, {
                                        page: {
                                            pageNo: 1,
                                            pageSize: this.pageSize,
                                        },
                                        params: {
                                            [this.config.queryField]: model.data[this.config.queryField],
                                        }
                                    })
                                        .then(res => {
                                            setTimeout(() => {
                                                model.children = res.data.records.map((item) => {
                                                    return {
                                                        id: this.$help.getUuid(),
                                                        loaded: false,
                                                        // label: `${item.indication}(${item.comNumber}药品)`,
                                                        label: item.indication,
                                                        data: item,
                                                        // nodeType: 'rect',
                                                        children: [],
                                                        type: 'indication',
                                                    }
                                                })
                                                if (res.data.totalCount > this.pageSize) {
                                                    model.children.push({
                                                        id: this.$help.getUuid(),
                                                        label: '查看全部',
                                                        isFull: true,
                                                        data: {},
                                                        end: true,
                                                        style: {
                                                            fill: '#DC2525',
                                                        },
                                                        parentTitle: model.data.comName,
                                                        parentModel: model.data,
                                                        config: this.config,
                                                        children: [],
                                                    })
                                                }

                                                this.antv.changeData()
                                            }, 300)
                                        })
                                        .finally(() => {
                                            this.loading = false
                                        })
                                } else {
                                    this.getIndicationChildren(model.data)
                                        .then(res => {
                                            setTimeout(() => {
                                                model.children = res
                                                this.antv.changeData()
                                            }, 300)
                                        })
                                        .finally(() => {
                                            this.loading = false
                                        })
                                }
                            }
                        })
                    })
            },
        }
    }
</script>